<template>
<div>
  <v-tabs v-model="tab" 
      background-color="#43C6D2"
      dark
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
  >
    <v-tabs-slider color="#EB8519"></v-tabs-slider>
    <v-tab>
      Informações do Contrato
    </v-tab>
    <v-tab>
      Datas e Valores
    </v-tab>
    <v-tab>
      Fiscais
    </v-tab>
    <v-tab>
      Interessados
    </v-tab>
    <v-tab>
      Garantia
    </v-tab>
  </v-tabs>
  <v-tabs-items v-model="tab">
    <v-tab-item> <!-- dados -->
      <v-card flat>
        <v-card-text>      
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field label="* Número"
                      dense
                      :rules="[
                        this.$validators.string.required,
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 20),
                      ]"
                      v-model.trim="value.numContrato"/>
          </v-col>        
          <v-col cols="12" sm="3">
            <lookup-dominio label="* Status"
                            :hidden-clear="true"
                            hint="Selecione o Status"
                            v-model="value.status"
                            :type="lookups.status"
                            :rules="[
                              this.$validators.notNullOrUndefined
                            ]"/>
          </v-col>  
          <v-col cols="12" md="3">
            <search-box
                label="* Modalidade"
                popup-label="Selecione uma Modalidade"
                :id.sync="value.idModalidade"
                :descricao.sync="value.descricaoModalidade"
                :hidden-clear="true"
                :value="modalidade"
                :loader-fn="loaderModalidade"
                item-key="id"
                item-text="descricao"
                :rules="[
                    this.$validators.notNullOrUndefined
                ]">
              <template v-slot:empty>
                <p class="ma-2">
                  Nenhuma Modalidade encontrada
                </p>
              </template>
            </search-box>
          </v-col>
          <v-col cols="12" md="3">
            <search-box
                label="* Tipo de Contrato"
                popup-label="Selecione um Tipo de Contrato"
                :id.sync="value.idTipoContrato"
                :descricao.sync="value.descricaoTipoContrato"
                :hidden-clear="true"
                :value="tipoContrato"
                :loader-fn="loaderTipoContrato"
                item-key="id"
                item-text="descricao"
                :rules="[
                    this.$validators.notNullOrUndefined
                ]">
              <template v-slot:empty>
                <p class="ma-2">
                  Nenhum Tipo de Contrato encontrada
                </p>
              </template>
            </search-box>
          </v-col>
          <v-col cols="12" md="4">
            <search-box
                label="* Contratante"
                popup-label="Selecione um Contratante"
                :id.sync="value.idContratante"
                :descCompleta.sync="value.descricaoContratante"
                :hidden-clear="true"
                :value="contratante"
                :loader-fn="loaderContratante"
                item-key="id"
                item-text="descCompleta"
                :rules="[
                    this.$validators.notNullOrUndefined
                ]">
              <template v-slot:empty>
                <p class="ma-2">
                  Nenhum Contratante encontrado
                </p>
              </template>
            </search-box>
          </v-col>
          <v-col cols="12" md="4">
            <search-box ref="comboUnidade"
                label="* Unidade"
                popup-label="Selecione uma Unidade"
                :id.sync="value.idUnidade"
                :descCompleta.sync="value.descricaoUnidade"
                :hidden-clear="true"
                :value="unidade"
                :loader-fn="loaderUnidade"
                item-key="id"
                item-text="descCompleta"
                :rules="[
                    this.$validators.notNullOrUndefined
                ]">
              <template v-slot:empty>
                <p class="ma-2">
                  Nenhuma Unidade encontrado
                </p>
              </template>
            </search-box>
          </v-col>      
          <v-col cols="12" md="4">
            <search-box
                label="* Contratado"
                popup-label="Selecione um Contratado"
                :id.sync="value.idContratado"
                :descricao.sync="value.descricaoContratado"
                :hidden-clear="true"
                :value="contratado"
                :loader-fn="loaderContratado"
                item-key="id"
                item-text="descricao"
                :rules="[
                    this.$validators.notNullOrUndefined
                ]">
              <template v-slot:empty>
                <p class="ma-2">
                  Nenhum Contratado encontrado
                </p>
              </template>
            </search-box>
          </v-col>         
          <v-col cols="12" sm="6">     
            <v-textarea 
                dense
                rows="6"
                label="* Objeto"
                :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 4000),
                ]"
                v-model.trim="value.objeto"/>
          </v-col>
          <v-col cols="12" sm="6">     
            <v-textarea 
                dense
                rows="6"
                label="Observação"
                :rules="[
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 400),
                ]"
                v-model.trim="value.observacao"/>
          </v-col> 
        </v-row>
        </v-card-text>
      </v-card>        
    </v-tab-item>
    <v-tab-item> <!-- Datas e Valores -->
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3">
                <v-text-field label="* Data Início"
                              dense
                              type="date"
                              v-model="value.dataInicioContrato"
                              :rules="[
                                  this.$validators.string.required,
                            ]"/>
            </v-col>
            <v-col cols="12" sm="3">
                <v-text-field label="* Data Término"
                              dense
                              type="date"
                              v-model="value.dataTerminoContrato"
                              :rules="[
                                  this.$validators.string.required,
                            ]"/>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field dense
                  label="Valor Mensal"
                  prefix="R$"
                  :rules="[
                      v => this.$validators.number.greaterThanOrEquals(v, 0)
                  ]"
                  v-model="value.valorMensal" type="number"/>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field dense
                  label="* Valor Global"
                  prefix="R$"
                  :rules="[
                      this.$validators.number.required,
                      v => this.$validators.number.greaterThanOrEquals(v, 0)
                  ]"
                  v-model="value.valorGlobal" type="number"/>
            </v-col>
            <v-col cols="12" sm="12">
              <DetalharItemForm v-model="value" :titulo="'Detalhamento dos itens'"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item> <!-- Fiscais -->
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <strong>Fiscal Administativo</strong>
              <v-divider/>
            </v-col>            
            <v-col cols="12" sm="6">
              <v-text-field label="* Nome"
                            dense
                            :rules="[
                              this.$validators.string.required,
                              v => this.$validators.string.greaterThanOrEquals(v, 1),
                              v => this.$validators.string.lessThanOrEquals(v, 100),
                          ]"
                          v-model.trim="value.responsavelAdmin"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="* Email"
                            dense
                            :rules="[
                                this.$validators.string.email,
                                v => this.$validators.string.lessThanOrEquals(v, 50),
                            ]"
                          v-model.trim="value.emailRespAdmin"/>
            </v-col>            
            <v-col cols="12">
              <strong>Fiscal Técnico</strong>
              <v-divider/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="* Nome"
                            dense
                            :rules="[
                              this.$validators.string.required,
                              v => this.$validators.string.greaterThanOrEquals(v, 1),
                              v => this.$validators.string.lessThanOrEquals(v, 100),
                          ]"
                          v-model.trim="value.responsavelTecni"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="* Email"
                            dense
                            :rules="[
                                this.$validators.string.email,
                                v => this.$validators.string.lessThanOrEquals(v, 50),
                            ]"
                          v-model.trim="value.emailRespTecni"/>
            </v-col>
            <v-col cols="12">
              <strong>Fiscal Financeiro</strong>
              <v-divider/>
            </v-col>   
            <v-col cols="12" sm="6">
              <v-text-field label="* Nome"
                            dense
                            :rules="[
                              this.$validators.string.required,                            
                              v => this.$validators.string.lessThanOrEquals(v, 50),
                            ]"
                          v-model.trim="value.responsavelFinan"/>
            </v-col>                         
            <v-col cols="12" sm="6">
              <v-text-field label="* Email"
                            dense
                            :rules="[
                                this.$validators.string.email,
                                v => this.$validators.string.lessThanOrEquals(v, 50),
                            ]"
                          v-model.trim="value.emailRespFinan"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item> <!-- Interessados -->
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" sm="12">
                  <strong>Informe o(s) interessado(s). Ele(s) receberá(ão) notificaçoes referentes a este contrato. </strong> 
                  <v-btn small color="secondary" @click="addInteressados()">
                    <v-icon>mdi-playlist-plus</v-icon>Add
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider/>
            </v-col>
            <v-col cols="12">
              <v-row v-for="(interessado, index) in value.interessados" :key="index">                
                <v-col cols="12" sm="5">
                  <v-text-field label="* Nome"
                              dense
                              v-model.trim="interessado.nome"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="* Email"
                                dense
                                v-model.trim="interessado.email"/>
                </v-col>
                <v-col cols="12" sm="1">
                  <v-btn small color="error" @click="removeInteressado(index)">
                    <v-icon>mdi-playlist-remove</v-icon>
                  </v-btn>                         
                </v-col>                           
              </v-row>
            </v-col>         
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>  
    <v-tab-item> <!-- Garantia -->
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="temGarantia"
                label="O contrato possui Garantia"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row v-show="temGarantia">
            <v-col cols="12" sm="6">
              <lookup-dominio label="Tipo da Garantia"
                            :hidden-clear="true"
                            hint="Selecione o Status"
                            v-model="value.idTipoGarantia"
                            :type="lookups.tipoGarantia"
                            />
            </v-col>  
            <v-col cols="12" sm="6">
              <v-text-field dense
                  label="Valor da Garantia"
                  prefix="R$"
                  v-model="value.valorGarantia" type="number"/>
            </v-col>            
            <v-col cols="12" sm="6">
                <v-text-field label="* Data Início Garantia"
                              dense
                              type="date"
                              v-model="value.dataInicioGarantia"/>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field label="* Data Término Garantia"
                              dense
                              type="date"
                              v-model="value.dataTerminoGarantia"
                              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item> 
  </v-tabs-items>
</div>      
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
//PKs
import {findAll as findAllModalidade} from "../../../api/modalidade";
import {findAll as findAllTipoContrato} from "../../../api/tipoContrato";
import {findAll as findAllContratante} from "../../../api/orgao";
import {findAll as findAllContratado} from "../../../api/contratado";
import {findAll as findAllUnidade} from "../../../api/unidade";
import DetalharItemForm from "../forms/DetalharItemForm";

export default {
  name: "ContratoForm",
  components: {SearchBox, LookupDominio, DetalharItemForm},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      tab: null,
      lookups: {
        status: ItemDeDominio.STATUS_CONTRATO,
        tipoGarantia: ItemDeDominio.ARR_TIPO_GARANTIA, 
      },
      temGarantia: false,
      loading: false,
      error: null,
    }
  },
  computed: {
    unidade() {
      if (this.value.idUnidade == null) return null;
      return {
        id: this.value.idUnidade,
        descCompleta: this.value.descricaoUnidade
      }
    },
    contratante() {
      if (this.value.idContratante == null) return null;
      return {
        id: this.value.idContratante,
        descCompleta: this.value.descricaoContratante
      }
    },
    contratado() {
      if (this.value.idContratado == null) return null;
      return {
        id: this.value.idContratado,
        descricao: this.value.descricaoContratado
      }
    },
    modalidade() {
      if (this.value.idModalidade == null) return null;
      return {
        id: this.value.idModalidade,
        descricao: this.value.descricaoModalidade
      }
    },
    tipoContrato() {
      if (this.value.idTipoContrato == null) return null;
      return {
        id: this.value.idTipoContrato,
        descricao: this.value.descricaoTipoContrato
      }
    },
  },
  watch: {
    'value.idContratante': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$refs.comboUnidade.resetValues();
          this.$refs.comboUnidade.search();
        }
      }
    },
    'temGarantia': {
      deep: true,
      handler(newValue) {
        console.log('watch: ',newValue);
        if (newValue) {
          this.value.temGarantia = 'S';
        } else{
          this.value.temGarantia = 'N';
        }
      }      
    }
  },  
  methods: {
    loaderContratante(page, search) {
      return findAllContratante(page, {
        nome: search
      })
    },
    loaderUnidade(page, search) {
      return findAllUnidade(page, {
        nome: search,
        idOrgao: this.value.idContratante
      })
    },
    loaderContratado(page, search) {
      return findAllContratado(page, {
        nome: search
      })
    },
    loaderModalidade(page, search) {
      return findAllModalidade(page, {
        nome: search
      })
    },
    loaderTipoContrato(page, search) {
      return findAllTipoContrato(page, {
        nome: search
      })
    },
    addInteressados() {
      this.value.interessados.push({
        id: "",
        nome: "",
        email: "",
      });
    },
    removeInteressado(index) {
      this.value.interessados.splice(index, 1);
    },
    marcaTemGarantia(valor) {
      console.log('marcaTemGarantia: ',valor);
      if (valor === "S"){
        this.temGarantia = true;
      } else {
        this.temGarantia = false;
      }
    }   
  },
  mounted() {
    this.marcaTemGarantia(this.value.temGarantia);
  }
}
</script>

<style scoped>

</style>