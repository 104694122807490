<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditContratoDialog :item="item" v-model="editDialogOpened"
                                @onSave="() => $refs.dataTable.refresh()"/>
    <GerenciarAnexosDialog :item="item" v-model="gerenciarAnexosDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>
    <div>
      <ContratoDataTable ref="dataTable"
                           @click:edit="editar"
                           @click:remove="remover"
                           @click:gerenciaAnexos="gerenciarAnexos"
                           :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import ContratoDataTable from "../../components/shared/datatables/ContratoDataTable";
import BaseView from "../BaseView";
import {findOne, remove} from "../../api/contrato";
import {findAll as findAllContratado} from '../../api/contratado';
import {findAll as findAllModalidade} from '../../api/modalidade';
import {findAll as findAllTipoContrato} from '../../api/tipoContrato';
import CreateEditContratoDialog from "../../components/shared/dialogs/CreateEditContratoDialog";
import GerenciarAnexosDialog from "../../components/shared/dialogs/GerenciarAnexosDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";

export default {
  name: "index",
  components: {CreateEditContratoDialog, BaseView, ContratoDataTable, GerenciarAnexosDialog},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      gerenciarAnexosDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Número do Contrato',
          column: 'numContrato',
          type: 'number'
        },
         {
          name: 'Descrição',
          column: 'descricao',
          type: 'string'
        },
        {
          name: 'Contratado',
          column: 'idContratado',
          type: 'autocomplete',
          optionsFactory: (search) => findAllContratado(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },
        {
          name: 'Tipo do Contrato',
          column: 'idTipoContrato',
          type: 'autocomplete',
          optionsFactory: (search) => findAllTipoContrato(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },          
        {
          name: 'Modalidade',
          column: 'idModalidade',
          type: 'autocomplete',
          optionsFactory: (search) => findAllModalidade(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        }, 
        {
          name: 'Status do Contrato',
          column: 'status',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.STATUS_CONTRATO
          })
        },        
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {
        idModalidade: null,
        idTipoContrato: null,
        idContratante: null,
        idContratado: null,
        numContrato: "",
        objeto: "",
        valorGlobal: 0,
        valorMensal: 0,
        dataInicioContrato: "",
        dataTerminoContrato: "",
        responsavelAdmin: "",
        responsavelFinan: "",
        responsavelTecni: "",
        status: "",
        observacao: "",
        interessados: [],
        itens: [],
        temGarantia: 'N',
      };
      this.editDialogOpened = true;
    },
    async editar(contrato) {
      const data = await this.getContrato(contrato.id);
      this.item = data;
      this.editDialogOpened = true;
    },
    gerenciarAnexos(item){
      this.item = JSON.parse(JSON.stringify(item));
      this.gerenciarAnexosDialogOpened = true;
    },
    async getContrato(id) {
      if (id){
        try {
          this.error = null;
          this.loading = true;
          const page = await findOne(id);
          let contrato = page.dados[0];
          return contrato;
        } catch (err) {
          this.error = err;
        } finally {
          this.loading = false;
        }
      }
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Contrato removido com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover o Contrato.`, {
          timeout: 6000
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>